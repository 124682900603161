import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import BannerRenderer from 'common/BannerRenderer';
import BodyRenderer from 'common/BodyRenderer';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { HomepageTypes } from '../../@types/content/homepage';

import 'styles/main.scss';

const HomePage: FC<{ data: HomepageTypes.HomepageType }> = ({ data: { homepage } }) => {
  const { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs } = homepage.seo;
  const { title, banner, modules, url, lang } = homepage;

  return (
    <Layout headerTransparent>
      <Seo
        {...{
          seo: homepage.seo,
          openGraph: homepage.openGraph,
          pageTitle: title,
          seoExternalHreflangs,
          pageUrl: url,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {banner.length > 0 ? (
        <div className="banners">{banner && <BannerRenderer {...banner[0]} />}</div>
      ) : null}
      <div id="modules">
        <BodyRenderer modules={modules} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    homepage {
      lang
      url
      seo {
        ...SEOStructureFragment
      }
      openGraph {
        ...OpenGraphFragment
      }
      title
      banner {
        ...FragmentBanners
      }
      modules {
        ...FragmentModules
      }
    }
  }
`;

export default HomePage;
